<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div v-if="giftSuccess">
      <div class="banner">
        <img class="bannerImg" :src="banner_image" alt="">
      </div>
      <div v-show="gift_source === 1">
        <!-- 原创 -->
        <div class="old_title">
          <!-- <p>{{ obj.quantity }}</p> -->
          <p>{{ obj.gift_name }}</p>
          <p>{{ introduce }}</p>
          <!-- <img src="@/static/ucenter-qie/mon.png" alt=""> -->
        </div>
        <div class="detailImg">
          <div class="detailImgTitle">
            <p>商品介绍</p>
          </div>
          <div style="padding-bottom:80px;">
            <img v-for="(item,index) in content" :key="index" class="imgsq" :src="item" alt="">
          </div>
        </div>
      </div>
      <div class="myFoot">
        <!-- <div class="price">
          <img src="@/static/ucenter-qie/mon.png" alt="">
          <p>{{ obj.quantity }}</p>
        </div> -->
        <div class="immediatelyExchange" style="width: 100%;">
          <!-- <button @click="RedeemNow()">立即兑换</button> -->
          <button style="width: 100%;" @click="selectSku=true">立即兑换</button>
        </div>
      </div>
    </div>
    <div v-else class="nodatas">
      <img src="@/static/giftIndex/new_kong.png" alt="kong">
      <p>{{ errorMsg }}</p>
    </div>
    <van-action-sheet v-model:show="selectSku" :round="true">
      <div class="mainContent">
        <van-icon class="icon-close" name="close" @click="closeSelect" />
        <div class="top_product">
          <div class="product_left">
            <img :src="bigImg" alt="">
          </div>
          <div class="product_right">
            <div class="product_price">
              <p>
                <span class="unit"><img src="@/static/giftIndex/mon.png" alt=""></span>
                <span class="price">{{ productPrice }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="product_sku">
          <!-- 商品规格 -->
          <div v-for="(item,index) in spec" :key="index" class="specification">
            <div class="title">{{ item.spec_name }}</div>
            <div class="specification_list">
              <div v-for="(val,key) in item.spec_value" :key="key" class="specification_item" @click="chooseSpecification(item,index,val,key)">
                <div class="item" :class="[defaultProductId[index]==val.spec_value_id?'on':'',index==val.disablePosition&&val.spec_value_id==val.disableSpecificationId?'disable':'']">
                  <span>{{ val.spec_value_name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="product_num">
            <div class="title">数量</div>
            <van-stepper v-model="num" disabled min="1" :max="1" @change="handleChange" />
          </div>
          <div class="astrict red">
            限购1件
          </div>
        </div>
        <div class="toBuy" @click="RedeemNow">
          立即兑换
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { getRow } from '@/api/my_user.js'
import { get_giftDetail, getMyScore } from '@/api/giftIndex.js'
import { getAppCommonStatis } from '@/api/hometop.js'
import { Toast } from 'vant'
import { math } from '@/utils/math.js'
export default {
  components: {
    heads

  },
  data() {
    return {
      giftSuccess: false,
      errorMsg: '',
      title_msg: '礼品详情',
      obj: {},
      introduce: '',
      content: [],
      gift_source: 1, // 区分京东还是原创  1是原创 2是京东
      banner_image: '',
      jd_introduction: '',
      jd_param: '',
      moneyName: '',

      selectSku: false, // 规格弹出窗
      spec: [], // 商品规格
      bigImg: '',
      productPrice: 0, // 产品价格
      maxNum: 100, // 最大可购数量
      num: Number(this.$route.query.num) || 1, // 产品购买数量
      // 默认选中有库存规格的产品id
      defaultProductId: [],
      check_lesson_sku_str: this.$route.query.sku_id || '', // 选中规格的产品sku
      check_lesson_sku_id: this.$route.query.spec_sku_id || '', // 选中规格的产品sku 的id
      copy_spec_sku: []
    }
  },
  mounted() {
    this.getScoreName()
    this.get_msg()
    this.getAppCommonStatis()
  },
  methods: {
    getScoreName() {
      const p = { type: 1 }
      getRow(p).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const result = res.result
          const name = result.name
          if (name) {
            this.moneyName = name
          }
        }
      })
    },
    // 关闭
    closeSelect() {
      this.selectSku = false
    },
    // 商品兑换数量
    handleChange(num) {
      this.num = num
      this.getProductPrice(Number(this.lessonSpecPrice), this.num)
    },
    getProductPrice(lessonSpecPrice, num = 1) {
      this.productPrice = math.multiply(lessonSpecPrice, num)
    },
    // 选择规格
    chooseSpecification(item, index, val, key) {
      console.log(item, index, val, key, '切换规格')
      // 如果没有在禁用状态下
      if (!val.disablePosition && val.disablePosition != 0) {
        this.maxNum = 100// 重置可购数量
        // // 高亮显示产品规格
        this.defaultProductId[index] = val.spec_value_id.toString()
        // console.log(this.defaultProductId)
        this.getCorrelation(this.defaultProductId, JSON.parse(JSON.stringify(this.copy_spec_sku)))
        // this.getProductInfo(val.spec_sku_id)
        // 查找所选规格对应的sku信息
        var productItem = this.getSpecInfo(this.defaultProductId.join('_'))
        // 获取产品价格折扣等信息
        // this.getProductInfo(productItem.spec_sku_id)
        this.getProductInfo(productItem)
      }
    },
    // 获取商品价格等信息
    getProductInfo(productItem) {
      for (let i = 0; i < this.spec_sku.length; i++) {
        if (this.spec_sku[i].sku_id == productItem.sku_id) {
          this.productPrice = this.spec_sku[i].price
          this.lessonSpecPrice = this.spec_sku[i].price
          this.check_lesson_sku_id = this.spec_sku[i].spec_sku_id
          // this.bigImg = this.spec_sku[i].img
        }
      }
      console.log(this.spec_sku, 'this.spec_sku')
      console.log(productItem, 'productItem')
      console.log(this.check_lesson_sku_id, 'check_lesson_sku_id')
      this.getProductPrice(Number(this.lessonSpecPrice), this.num)
    },
    // 封装取当前线路相关的规格线路
    getCorrelation(checkId, allSku) { // 第一个参数为当前线路id数组 ，第二个为所有规格线路
      this.spec = JSON.parse(JSON.stringify(this.copy_spec_list))
      this.spec_sku = JSON.parse(JSON.stringify(this.copy_spec_sku))
      // console.log(checkId, allSku, '接收参数')
      for (let i = 0; i < allSku.length; i++) {
        allSku[i].intersection = this.intersection(allSku[i].spec_sku_id_arr, checkId)
        // 差集
        allSku[i].diff = this.diff(allSku[i].spec_sku_id_arr, checkId)
      }
      // console.log(allSku, 'allSkuallSku')
      // 找到跟当前规格线路相关且库存为0 的条目
      // 跟当前线路相关规格路线为只有一个规格不同的规格组合
      const correlationData = allSku.filter((item) => {
        return item.intersection.length == checkId.length - 1 && item.num == 0
      })
      // 确定需要禁用规格的按钮位置
      for (let i = 0; i < correlationData.length; i++) {
        // 添加规格位置，disablePosition为当前规格列表所在规格数组的下标
        // disableSpecificationId为当前禁用规格id 所在规格列表的下标
        correlationData[i].disablePosition = correlationData[i].spec_sku_id_arr.indexOf(correlationData[i].diff.join(''))
        correlationData[i].disableSpecificationId = correlationData[i].diff.join('')
      }
      // console.log(correlationData, 'correlationDatacorrelationData')
      // 把产品规格需要禁用的坐标放到渲染页面的规格列表中
      for (let i = 0; i < correlationData.length; i++) {
        // 循环规格列表
        for (let j = 0; j < this.spec.length; j++) {
          if (correlationData[i].disablePosition == j) {
            // 循环规格值
            for (let k = 0; k < this.spec[j].spec_value.length; k++) {
              if (correlationData[i].disableSpecificationId == this.spec[j].spec_value[k].spec_value_id) {
                this.spec[j].spec_value[k].disablePosition = correlationData[i].disablePosition
                this.spec[j].spec_value[k].disableSpecificationId = correlationData[i].disableSpecificationId
              } else {
                continue
              }
            }
          } else {
            continue
          }
        }
      }
      // console.log(correlationData, '含有库存为0的规格线路')
      // console.log(this.spec_sku)
      // console.log(this.spec)
    },
    // 找出商相同的规格id
    // [1,1,3]  [2,3,3] 返回 [3]
    intersection(arr1, arr2) {
      const common = []
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] === arr2[i]) {
          common.push(arr1[i])
        }
      }
      return common
    },
    // 找出商不同的规格id
    // [2,3,3] [2,3,2]返回[2]
    // [1,1,3] [2,3,3]返回[2,3]
    diff(arr1, arr2) {
      const common = []
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          common.push(arr1[i])
        }
      }
      return common
    },
    // 根据所选规格查找对应产品规格信息
    getSpecInfo(spec) { // 传入规格值 1_5_7
      for (let i = 0; i < this.spec_sku.length; i++) {
        if (this.spec_sku[i].sku_id == spec) {
          return this.spec_sku[i]
        }
      }
    },
    RedeemNow(index) {
      getMyScore().then(res => {
        if (res.result >= this.obj.quantity) {
          this.$router.push({
            path: '/gift/RedeemNow',
            query: {
              id: this.$route.query.id,
              num: this.num,
              spec_sku_id: this.check_lesson_sku_id
            }
          })
        } else {
          const msg = this.moneyName + '不足'
          Toast(msg)
        }
      })
    },
    get_msg() {
      var params = {
        id: this.$route.query.id
      }
      get_giftDetail(params).then(res => {
        const errNo = res.errNo
        const result = res.result
        this.bigImg = result.img
        if (errNo === 0 && result.gift_source) {
          this.giftSuccess = true
          this.obj = result
          this.gift_source = this.obj.gift_source
          if (this.gift_source === 2) {
            // this.banner_image = 'http://img13.360buyimg.com/n12/' + this.obj.img
            this.banner_image = this.obj.img
            this.jd_introduction = this.obj.info.introduction
            this.jd_param = this.obj.info.param
          } else {
            this.banner_image = this.obj.img
            this.introduce = res.result.info.introduce
            this.content = res.result.info.content
          }
          // 商品规格、套餐
          // this.productArr = data.product
          this.spec = res.result.spec
          // 各种规格对应的商品价格及库存
          this.spec_sku = res.result.spec_sku
          this.spec_sku.forEach((item) => {
            item.spec_sku_id_arr = item.sku_id.split('_')
          // item.spec_sku_id_sort = JSON.parse(JSON.stringify(item.spec_sku_id_arr)).sort()
          })
          // console.log('所有规格列表', this.spec_sku)
          // 深克隆一组数据方便重置选项列表
          this.copy_spec_list = JSON.parse(JSON.stringify(this.spec))
          // console.log(this.copy_spec_list, 'copy_spec_list')
          this.copy_spec_sku = JSON.parse(JSON.stringify(this.spec_sku))
          // console.log(this.copy_spec_sku, 'this.copy_spec_sku')
          // 如果没有选中 取出一条库存不为0的产品规格进行默认选中，否则高亮之前选中状态
          // 若之前高亮状态库存为0 则高亮库存不为0的产品规格
          if (!this.$route.query.spec_sku_id) {
            console.log(this.getDefaultPro(this.spec_sku).defaultId, '高亮条目')
            this.defaultProductId = this.getDefaultPro(this.spec_sku).defaultId
            this.check_lesson_sku_str = this.getDefaultPro(this.spec_sku).sku_id
            this.check_lesson_sku_id = this.getDefaultPro(this.spec_sku).spec_sku_id
            // 获取所选规格价格
            this.getProductInfo(this.getDefaultPro(this.spec_sku))
          } else {
            for (let i = 0; i < this.spec_sku.length; i++) {
              if (this.spec_sku[i].spec_sku_id == this.$route.query.spec_sku_id) {
              // 如果之前选中没有库存 执行上面逻辑
                if (this.spec_sku[i].num == 0) {
                  this.defaultProductId = this.getDefaultPro(this.spec_sku).defaultId
                  this.check_lesson_sku_str = this.getDefaultPro(this.spec_sku).sku_id
                  this.check_lesson_sku_id = this.getDefaultPro(this.spec_sku).spec_sku_id
                  // 获取所选规格价格
                  this.getProductInfo(this.getDefaultPro(this.spec_sku))
                } else {
                // 取之前高亮条目
                  this.defaultProductId = this.$route.query.sku_id.split('_')
                  this.check_lesson_sku_str = this.$route.query.sku_id
                  this.check_lesson_sku_id = this.$route.query.spec_sku_id
                  this.getProductInfo(this.$route.query.spec_sku_id)
                }
                break
              }
            }
          }

          // 取出所有跟当前线路相关的规格组合
          this.getCorrelation(this.defaultProductId, this.spec_sku)
        } else {
          this.giftSuccess = false
          const msg = '该商品不存在或已下架~'
          if (typeof (res.result) === 'string') {
            msg = res.result
          }
          this.errorMsg = msg
          this.$toast(msg)
        }
      })
    },
    // 取出一条库存不为0的产品规格 （高亮显示id）
    getDefaultPro(arr) { // arr为产品规格详细列表
      for (let i = 0; i < arr.length; i++) {
      // 遍历产品规格列表，寻找产品规格中的库存不为0的产品规格 （高亮显示id） 并将其存储在变量中
        if (arr[i].num > 0) {
          //  取当前规格的spec_sku_id默认选中商品，规格高亮
          return {
            defaultId: arr[i].sku_id.split('_'),
            spec_sku_id: arr[i].spec_sku_id,
            sku_id: arr[i].sku_id
          }
        }
      }
    },
    // 三方统计
    getAppCommonStatis() {
      getAppCommonStatis().then(res => {
        console.log(res, 'res')
        this.statis = res.result
        var bd_script = document.createElement('script')
        bd_script.type = 'text/javascript'
        // bd_script.innerHTML = this.statis.baidu_h5
        // console.log(this.statis, 'this.statis')
        document.head.appendChild(bd_script)
        var cc_script = document.createElement('script')
        cc_script.type = 'text/javascript'
        if (this.statis) {
          cc_script.innerHTML = this.statis.circle
        }
        document.head.appendChild(cc_script)
        var cz_script = document.createElement('script')
        cz_script.type = 'text/javascript'
        cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
        // cz_script.innerHTML = this.statis.cnzz
        document.head.appendChild(cz_script)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.red{
  color:#ee2e2e;
}
.astrict{
  margin-top:8px;
}
.detailImgTitle {
  font-size: 30px;
  // font-weight: 600;
  color: #444444;
  text-align: center;
  padding: 59px 0 52px 0;
}
.myFoot {
  width: 300px;
  height: 100px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}
.imgsq {
  width: 100%;
}
.myFoot .price img {
  position: absolute;
  width: 41px;
  height: 40px;
  left: 31px;
  // top: 28px;
  top: 23px;
}
.myFoot .price p {
  margin: 0 auto;
  line-height: 90px;
  width: 285px;
  // height: 37px;
  // font-size: 48px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ee2e2f;
}
.myFoot .price {
  width: 450px;
  height: 100px;
  background: #fff;
}
.immediatelyExchange button {
  width: 300px;
  height: 100px;
  background: #ee2e2f;
  color: #fff;
  font-size: 32px;
}
.myFoot {
  width: 100%;
  height: 100px;
}
.detailImg {
  width: 100%;
}
.banner {
  width: 100%;
  // height: 401px;
}
.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // background: antiquewhite;
}
.old_title {
  position: relative;
  width: 100%;
  // height: 126px;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.06);
}
.old_title img {
  width: 41px;
  height: 40px;
  position: absolute;
  left: 29px;
  // top: 25px;
  top: 28px;
}
// .old_title p:nth-child(1) {
//   width: 104px;
//   // height: 34px;
//   // font-size: 44px;
//   // line-height: 53px;
//   font-size: 36px;
//   font-family: PingFang SC;
//   font-weight: 600;
//   color: #ee2e2f;
//   padding: 25px 0 0 84px;
// }
.old_title p:nth-child(1) {
  width: 645px;
  // font-size: 36px;
  // font-weight: 600;
  font-size: 30px;
  color: #222222;
  padding: 30px 0 0 0;
  margin: 0 0 0 25px;
}
.old_title p:nth-child(2) {
  width: 662px;
  // height: 64px;
  // font-size: 24px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 41px;
  padding: 15px 0 0 25px;
}

.jd_title {
  position: relative;
  width: 100%;
  // height: 326px;
  padding-bottom: 20px;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.06);
}
.jd_title img {
  width: 41px;
  height: 40px;
  position: absolute;
  left: 29px;
  top: 28px;
}
.jd_title p:nth-child(1) {
  width: 104px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ee2e2f;
  padding: 25px 0 0 84px;
}
.jd_title p:nth-child(2) {
  width: 645px;
  font-size: 30px;
  color: #222222;
  padding: 30px 0 0 0;
  margin: 0 0 0 25px;
}
.jd_title p:nth-child(3) {
  width: 662px;
  // height: 64px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 41px;
  padding: 15px 0 0 25px;
}
.jd_style {
  padding-bottom: 100px;
  /deep/img {
    width: 100%;
  }
  /deep/div {
    width: 100%;
  }
}
/deep/ .ssd-module-wrap div {
  // background-size: 100% 51% !important;
  // height: 1500px;
  overflow: hidden;
}
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
button{
  outline: none;
  border:0;
}

.mainContent{
  height: 100%;
  position:relative;
  padding: 20px;
  box-sizing:border-box;
  .icon-close{
    font-size: 40px;
    font-weight: 600;
    position:absolute;
    top:60px;
    right: 60px;
  }
  .top_product{
    width: 100%;
    display: flex;
    margin-bottom:40px;
    padding-top: 60px;
    .product_left{
      width: 240px;
      height: 240px;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .product_right{
      flex:1;
      padding:0 0 40px 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .product_price{
        color:#ee2e2e;
        font-size: 24px;
        margin-bottom:20px;
        .price{
          font-weight: 600;
          font-size: 36px;
        }
        .unit{
            display: inline-block;
            width:40px;
            font-family: PingFangSC-Medium;
            font-size: 30px;
            letter-spacing: 0;
            font-weight: 500;
            margin-right: 4px;
            img{
              width: 41px;
              height: 40px;
            }
          }
        .price_bottom{
          display: flex;
          margin-top:10px;
          align-items: center;
          .unit{
            display: inline-block;
            width:40px;
            font-family: PingFangSC-Medium;
            font-size: 30px;
            letter-spacing: 0;
            font-weight: 500;
            margin-right: 4px;
          }
          .money{
            font-size: 36px;
            letter-spacing: 0;
          }
          .vip_discount_price{
              font-size: 28px;
              color: #333333;
              letter-spacing: 0;
              font-weight: 400;
            }
          .vip{
            flex-shrink: 0;
            .vip_status{
                margin-right: 5px;
                z-index:200;
                left:17px;
                top:8px;
                display: flex;
                .is_vip{
                  border-radius: 18px 0 0 18px;
                  font-size: 24px;
                  background: rgba(0, 0, 0, 0.8);
                  // height: 28px;
                  // line-height: 28px;
                  text-align: center;
                  position: relative;
                  left: 14px;
                  // font-weight:600;
                  color: rgba(255,255,255,0.90);
                  padding: 0 10px;
                }
                .discount{
                  color: #4A351E;
                  background-image: linear-gradient(134deg, #F8DAA6 0%, #EABD6D 100%);
                  border-radius:0 18px 18px 0;
                  font-size: 24px;
                  // height: 28px;
                  // line-height: 28px;
                  text-align: center;
                  padding: 0 12px 0 20px;
                  // font-weight:600;
                }
              }
          }
        }
      }
      .discount_price{
        background: #ee2e2e;
        padding: 4px 14px;
        color: #fff;
        font-size:30px;
        width: max-content;
        border-radius: 40px;
        .unit{
          font-size: 24px;
        }
        .price{
          font-weight: 600;
          // font-size: 36px;
        }
      }
      .other_info{
          margin-top: 20px;
          font-size: 24px;
          color:#8D8D8D;
        }
    }
  }
  .product_sku{
    max-height:calc(100vh - 700px);
    padding-bottom: 120px;
    overflow-y: scroll;
    .title{
      font-weight: 800;
      font-size: 32px;
      margin-bottom: 40px;
    }
    .specification_list{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .specification_item{
        position: relative;
        .product_status{
          position:absolute;
          background: #ee2e2e;
          padding:0px 4px;
          border-radius: 50%;
          color:#fff;
          top: -11px;
          right: 10px;
          font-size:24px;
        }
        .item{
          display: flex;
          align-items: center;
          height: 40px;
          background: #F3F3F3;
          font-family: PingFangSC-Medium;
          font-size: 24px;
          color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          padding:8px 30px;
          border: 1px solid #F3F3F3;
          margin-right: 20px;
          margin-bottom:30px;
          border-radius: 40px;
          cursor:pointer;
          img{
            display: block;
            width: 26px;
            height: 26px;
            margin-right:4px;
          }
        }
        .on{
          background: #FFFFFF;
          border: 1px solid #ee2e2e;
          color:#ee2e2e;
        }
        .disable{
          background: #F5F7FA;
          border: 1px solid #E4E7ED;
          color: #333333;
          cursor: not-allowed;
        }
      }

    }
    .product_num{
      .title{
        margin-bottom: 0;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .toBuy{
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 38px;
    font-weight: 600;
    position: absolute;
    bottom:0;
    left:0;
    background: #ee2e2e;
    color: #fff;
    border-radius: 12px 12px 0px 0px;
  }
}
/deep/ .van-action-sheet{
  max-height: 100vh;
}
</style>
